import React from "react"
import Hero from "./hero/Hero"
import Feature from "./feature/Feature"
import Services from "./services/Services"
import New from "./new/New"
import Partner from "./partner/Partner"
import Testimonials from "./testimonial/Testimonials"
import Counter from "./counter/Counter"
import Search from "../search/Search"


const Home = () => {
  return (
    <>
      <Hero />
      <Search />
      <Feature />
      {/* <Counter /> */}
      <New />
      <Services />
      <Partner />
      {/* <Testimonials /> */}
    
    </>
  )
}

export default Home