import React from 'react';
import {Link} from "react-router-dom";
import ScrollToTop from '../ScrollToTop'; 

const Header = () =>{

    
    return(
        <>
 <ScrollToTop />
<div className="preloader">
      <div className="spinner"></div>
    </div>

       <header className="header header-sticky">
    <div className="header-bottom menu-center">
        <div className="container">
            <div className="row justify-content-between">
                
                {/* <!--Logo start--> */}
                <div className="col mt-10 mb-10">
                    <div className="logo">
                        <Link to="/"><img src="assets/images/logo.png" alt="" /></Link>
                    </div>
                </div>
                {/* <!--Logo end--> */}
                
                {/* <!--Menu start--> */}
                <div className="col d-none d-lg-flex">
                    <nav className="main-menu">
                        <ul>
                            <li><Link to="/">Home</Link> </li>
                            <li className="has-dropdown"><Link to="/sale">Sell</Link>
                                <ul className="sub-menu">
                                    <li><Link to="/sale_bungalow">Bungalow</Link> </li>
                                    <li><Link to="/sale_flat">Flat</Link> </li>
                                    <li><Link to="/sale_house">House</Link> </li>
                                    <li><Link to="/sale_land">Land</Link> </li>
                                </ul>
                            </li>

                            <li className="has-dropdown"><Link to="/rent">Rent</Link>
                                <ul className="sub-menu">
                                    <li><Link to="/rent_bungalow">Bungalow</Link> </li>
                                    <li><Link to="/rent_flat">Flat</Link> </li>
                                    <li><Link to="/rent_house">House</Link> </li>
                                </ul>
                            </li>
                            <li><Link to="/about">about</Link> </li>
                            {/* <li><Link to="/blog">blog</Link> </li> */}
                            <li><Link to="/contact">contact</Link> </li>
                        
                        </ul>
                    </nav>
                    
                </div>
                {/* <!--Menu end--> */}
                

                {/* <!--User start--> */}
                <div className="col mr-sm-50 mr-xs-50">
                    <div className="header-user " style={{ background: '#004395', padding: '5px 19px', borderRadius: '6px'}}>
                         <Link to="/ads" className="user-toggle" style={{color:'white'}}><i className="pe-7s-film"></i><span>Advertisment </span></Link>
                    </div>
                </div>
                {/* <!--User end--> */}
            </div>
            
            {/* <!--Mobile Menu start--> */}
            <div className="row">
                <div className="col-12 d-flex d-lg-none">
                    <div className="mobile-menu"></div>
                </div>
            </div>
            {/* <!--Mobile Menu end--> */}
            
        </div>
    </div>
</header>
        </>
    )
}

export default Header;